import React, { useRef, useState } from 'react';
import { useIntl, Link, FormattedHTMLMessage } from 'gatsby-plugin-intl';
import { useLocation } from '@reach/router';
import { css } from '@emotion/core';
import { mainColor, greyColor } from '../../constants/colors';
import { menu } from '../../constants/menu';
import { Desktop, Tablet } from '../../constants/responsive';
import Button from '../Button';
import Container from '../Container';
import LanguageSwitcher from '../LanguageSwitcher';
import { useAppContext } from '../../context/AppContext';
import ScrollContainer from 'react-indiana-drag-scroll';
import '../../styles/header/desktop-menu.scss';
import { langMap } from '../../constants/dataMap';
import ReqDemoBtn from '../ReqDemo';

// 【二级目录】
const SecDir = ({ name }) => {
  const intl = useIntl();
  const location = useLocation();
  const isBespoke = location.pathname.split('/')[4] === 'bespoke';
  return (
    <div
      className={`${name === 'bespoke-investors' ? 'bespoke-sec-dir' : ''} ${
        isBespoke ? 'active' : ''
      }`}
      onClick={() => {
        if (name === 'bespoke-investors' && !isBespoke) {
          window.location.assign(
            `/${intl.locale}/product/bespoke-investors/bespoke`
          );
        }
      }}
      css={css`
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        margin-top: 21px;
        ${Tablet} {
        }
      `}
    >
      {intl.formatMessage({ id: `menu.${name}` })}
    </div>
  );
};
// 【三级目录】
const ThreeLevelDir = ({
  name,
  url,
  goOut = false,
  disabled = false,
  tagText = '',
}) => {
  const intl = useIntl();
  const location = useLocation();
  const activePage = location.pathname.indexOf(url) !== -1;
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        margin-top: 18px;
        ${Tablet} {
        }
      `}
    >
      <div
        css={css`
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
          font-weight: ${activePage ? 'bold' : 'normal'};
          ${Tablet} {
          }
          a,
          a:active,
          a:visited {
            color: ${activePage ? mainColor : 'inherit'};
            ${Tablet} {
              color: ${activePage ? mainColor : 'inherit'};
            }
          }
          a:hover {
            color: ${mainColor};
          }
          pointer-events: ${disabled ? 'none' : 'auto'};
        `}
      >
        {/* 先判断链接是否跳出本网站 */}
        {goOut ? (
          <a href={url}>
            <span
              css={css`
                color: ${disabled ? greyColor : 'inherit'};
              `}
            >
              {intl.formatMessage({ id: `menu.${name}` })}
            </span>
          </a>
        ) : (
          <Link to={`/${url}`}>
            <span
              css={css`
                color: ${disabled ? greyColor : 'inherit'};
              `}
            >
              {intl.formatMessage({ id: `menu.${name}` })}
            </span>
          </Link>
        )}
      </div>
      {/* tag */}
      {tagText && (
        <div
          css={css`
            height: 15px;
            vertical-align: middle;
            background: #00b2ff;
            border-radius: 7.5px;
            padding: 0 5px;
            margin-left: 8px;
            display: flex;
            align-items: center;

            font-weight: bold;
            font-size: 10px;
            line-height: 21px;
            color: #ffffff;
          `}
        >
          {tagText}
        </div>
      )}
    </div>
  );
};

const HamburgerMenu = () => {
  const intl = useIntl();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(location.pathname.split('/')[2]);
  const isInstitutions = false;
  const isBespoke = false;
  const isProfessional = false;
  const textColor = isInstitutions || isProfessional ? 'white' : 'black';
  const menuBgColor =
    isInstitutions || isProfessional ? 'white' : isBespoke ? '#ccc' : 'black';
  const backgroundColor = isInstitutions
    ? '#003e86'
    : isProfessional
    ? '#14123c'
    : 'white';
  const {
    institutionsFormRef,
    professionalInvestorsFormRef,
    mainLandUrl,
  } = useAppContext();
  const lang = langMap[intl.locale];
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        ${Desktop} {
          display: none;
        }
        .hamburger {
          height: 14px;
          display: inline-block;
          cursor: pointer;
          transition-property: opacity, filter;
          transition-duration: 0.15s;
          transition-timing-function: linear;
          font: inherit;
          color: inherit;
          text-transform: none;
          background-color: transparent;
          border: 0;
          margin: 0;
          overflow: visible;
        }
        .hamburger.is-active .hamburger-inner,
        .hamburger.is-active .hamburger-inner::before,
        .hamburger.is-active .hamburger-inner::after {
          background-color: ${menuBgColor};
        }

        .hamburger-box {
          width: 22px;
          height: 14px;
          display: inline-block;
          position: relative;
        }

        .hamburger-inner {
          display: block;
          top: 50%;
          margin-top: -2px;
        }
        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
          width: 20px;
          height: 2px;
          background-color: ${menuBgColor};
          border-radius: 2px;
          position: absolute;
          transition-property: transform;
          transition-duration: 0.15s;
          transition-timing-function: ease;
        }
        .hamburger-inner::before,
        .hamburger-inner::after {
          content: '';
          display: block;
        }
        .hamburger-inner::before {
          top: -6px;
        }
        .hamburger-inner::after {
          bottom: -6px;
        }
        .hamburger--squeeze .hamburger-inner {
          transition-duration: 0.075s;
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
        .hamburger--squeeze .hamburger-inner::before {
          transition: top 0.075s 0.12s ease, opacity 0.075s ease;
        }
        .hamburger--squeeze .hamburger-inner::after {
          transition: bottom 0.075s 0.12s ease,
            transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        .hamburger--squeeze.is-active .hamburger-inner {
          transform: rotate(45deg);
          transition-delay: 0.12s;
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        .hamburger--squeeze.is-active .hamburger-inner::before {
          top: 0;
          opacity: 0;
          transition: top 0.075s ease, opacity 0.075s 0.12s ease;
        }
        .hamburger--squeeze.is-active .hamburger-inner::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.075s ease,
            transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      `}
    >
      <div
        css={css`
          margin-right: 16px;
          height: 27px;
          ${Tablet} {
            height: 36px;
          }
        `}
      >
        <ReqDemoBtn />
      </div>
      <div
        css={css`
          display: none;
          margin-right: 30px;
          @media (min-width: 470px) {
            display: flex;
            align-items: center;
          }
        `}
      >
        {isInstitutions && (
          <Button
            scrollToRef={institutionsFormRef}
            text={intl.formatMessage({ id: 'menu.contact-us' })}
          />
        )}
        {isBespoke && (
          <Button
            scrollToRef={professionalInvestorsFormRef}
            text={intl.formatMessage({ id: 'v5.hk_overseas.book_appointment' })}
            css={css`
              background: #4c2480;
              border-color: #4c2480;
              box-shadow: none;
            `}
            onClick={() => {
              window.location.assign(
                `/${intl.locale}/product/bespoke-investors/appointment/`
              );
            }}
          />
        )}
        {!isInstitutions && !isBespoke && (
          <a
            css={css`
              @media (min-width: 1200px) {
                display: none;
              }
            `}
            onClick={() => {
              const devOrTestEvnReg = /(^test-)|(^localhost)|(^127)|(^0)/;
              let domain = `https://smarthk.aqumon.com/#/login?lang=${lang}`;
              if (devOrTestEvnReg.test(window.location.hostname)) {
                // 测试环境
                domain = `https://test-smarthk.aqumon.com/#/login?lang=${lang}`;
              }
              window.location.href = `${domain}${
                sessionStorage.qd ? `&qd=${sessionStorage.qd}` : ''
              }`;
            }}
          >
            <span>{intl.formatMessage({ id: 'menu.login1' })}</span>
          </a>
        )}
      </div>

      <button
        className={`hamburger hamburger--squeeze ${open ? 'is-active' : ''}`}
        type='button'
        onClick={() => {
          if (!open) {
            setExpanded(location.pathname.split('/')[2]);
          } else {
            setExpanded('');
          }
          setOpen(!open);
        }}
      >
        <span className='hamburger-box'>
          <span className='hamburger-inner'></span>
        </span>
      </button>
      <ScrollContainer
        css={css`
          position: fixed;
          top: 70px;
          left: 0px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          width: 100%;
          height: ${open ? '100vh' : '0px'};
          background: ${backgroundColor};
          opacity: ${open ? 1 : 0};
          transition: all 0.2s ease;
          padding-top: 18px;
          ${Tablet} {
            top: 100px;
          }
        `}
      >
        {Object.keys(menu).map((title) => {
          const activeTitle = location.pathname.split('/')[2] === title;
          if (title === 'promotions') {
            return (
              <Link
                to={`/${title}`}
                key={title}
                css={css`
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  // border-top: 2px solid #c9c9c9;
                  cursor: pointer;
                  flex: 0 0 auto;
                `}
              >
                <Container>
                  <div>
                    <div
                      css={css`
                        padding: 20px 0px;
                        font-size: 18px;
                        line-height: 1;
                        font-weight: 500;
                        position: relative;
                        color: ${activeTitle ? mainColor : textColor};
                        ${Tablet} {
                          padding: 30px 0px;
                          font-size: 18px;
                        }
                      `}
                    >
                      {intl.formatMessage({ id: `menu.${title}` })}
                    </div>
                  </div>
                </Container>
              </Link>
            );
          }
          if (title === 'blog') {
            return (
              <Link
                to={'/blog'}
                key={title}
                css={css`
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  border-top: 2px solid #c9c9c9;
                  cursor: pointer;
                  flex: 0 0 auto;
                `}
              >
                <Container>
                  <div>
                    <div
                      css={css`
                        padding: 20px 0px;
                        font-size: 20px;
                        line-height: 1;
                        font-weight: bold;
                        position: relative;
                        color: ${activeTitle ? mainColor : textColor};
                        ${Tablet} {
                          padding: 30px 0px;
                          font-size: 30px;
                        }
                      `}
                    >
                      {intl.formatMessage({ id: `menu.${title}` })}
                    </div>
                  </div>
                </Container>
              </Link>
            );
          }
          return (
            <div
              key={title}
              css={css`
                width: 100%;
                display: flex;
                justify-content: center;
                // border-top: 1px solid #eee;
                flex: 0 0 auto;
                cursor: pointer;
              `}
              onClick={() => {
                if (expanded === title) {
                  setExpanded('');
                } else {
                  setExpanded(title);
                }
              }}
            >
              <Container
                css={css`
                  margin-bottom: 25px;
                  ${Tablet} {
                    margin-bottom: 27px;
                  }
                `}
              >
                {/* 【一级目录】 */}
                <div
                  css={css`
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24px;
                    position: relative;
                    color: ${activeTitle ? mainColor : textColor};
                    ${Tablet} {
                      display: flex;
                      font-size: 18px;
                      font-weight: 500;
                      line-height: 25px;
                    }
                  `}
                >
                  {intl.formatMessage({ id: `menu.${title}` })}
                  {/* 【箭头 - 右线段】 */}
                  <div
                    css={css`
                      width: 12px;
                      height: 2px;
                      border-radius: 2px;
                      background: ${textColor};
                      position: absolute;
                      top: 50%;
                      right: -4px;
                      transform: translate(-50%, -50%)
                        ${expanded === title
                          ? 'rotate(45deg)'
                          : 'rotate(-45deg)'};
                      transition: all 0.2s ease;
                    `}
                  />
                  {/* 【箭头 - 左线段】 */}
                  <div
                    css={css`
                      width: 12px;
                      height: 2px;
                      border-radius: 2px;
                      background: ${textColor};
                      position: absolute;
                      top: 50%;
                      right: 4px;
                      transform: translate(-50%, -50%)
                        ${expanded === title
                          ? 'rotate(-45deg)'
                          : 'rotate(45deg)'};
                      transition: all 0.2s ease;
                    `}
                  />
                </div>
                {/* 【二级目录】 */}
                <div
                  css={css`
                    overflow: hidden;
                    transition: all 0.2s ease;
                    height: ${expanded === title ? 'auto' : '0px'};
                    ${Tablet} {
                    }
                  `}
                >
                  {title === 'S-T-S' && (
                    <div
                      css={css`
                        // 机构和专业投资者目录字要白色
                        color: ${isInstitutions || isProfessional
                          ? '#ffffff'
                          : 'black'};
                        a,
                        a:active,
                        a:visited {
                          color: ${isInstitutions || isProfessional
                            ? '#ffffff'
                            : 'black'};
                        }
                        display: flex;
                        flex-direction: column;
                        margin-left: 20px;
                        ${Tablet} {
                          display: flex;
                          flex-direction: row;
                          width: 626px;
                          justify-content: space-between;
                          margin-left: 24px;
                          // margin-bottom: 20px;
                        }
                      `}
                    >
                      <div
                        css={css`
                          margin-right: 55px;
                        `}
                      >
                        <ThreeLevelDir
                          name='Digital'
                          url='product/bespoke-investors/bespoke/'
                        />
                        <ThreeLevelDir
                          name='Mainland'
                          goOut
                          url={mainLandUrl}
                        />
                        <ThreeLevelDir name='App' url='product/aqumon' />
                        <ThreeLevelDir name='fee' url='resources/fee' />
                      </div>
                    </div>
                  )}
                  {title !== 'S-T-S' &&
                    menu[title].map((page) => {
                      const activePage =
                        location.pathname.split('/')[3] === page;
                      return (
                        <div key={page} css={css``}>
                          {title === 'wealthTech' && (
                            <div
                              css={css`
                                // 机构和专业投资者目录字要白色
                                color: ${isInstitutions || isProfessional
                                  ? '#ffffff'
                                  : 'black'};
                                a,
                                a:active,
                                a:visited {
                                  color: ${isInstitutions || isProfessional
                                    ? '#ffffff'
                                    : 'black'};
                                }
                                display: flex;
                                flex-direction: column;
                                margin-left: 20px;
                                ${Tablet} {
                                  display: flex;
                                  flex-direction: row;
                                  width: 626px;
                                  justify-content: space-between;
                                  margin-left: 24px;
                                }
                              `}
                            >
                              {/* 【个人用户 目录】 */}
                              <div
                                css={css`
                                  ${Tablet} {
                                    flex: 0 0 30%;
                                  }
                                `}
                              >
                                <SecDir name='product' />
                                <ThreeLevelDir
                                  name='raas'
                                  url='product/institutions/advisory'
                                />
                                <ThreeLevelDir
                                  name='Fund-Supermarket'
                                  url='product/institutions/fundsuper'
                                />
                                <ThreeLevelDir
                                  name='smart-fund'
                                  url='product/institutions/smartfund'
                                />
                              </div>
                              {/* 【机构用户 目录】 */}
                              <div
                                css={css`
                                  ${Tablet} {
                                    flex: 0 0 30%;
                                  }
                                `}
                              >
                                <SecDir name='Solutions' />
                                <ThreeLevelDir name='Brokers' disabled />
                                <ThreeLevelDir name='Asset' disabled />
                                <ThreeLevelDir name='Banks' disabled />
                                <ThreeLevelDir name='Insurance' disabled />
                              </div>
                            </div>
                          )}

                          {title !== 'wealthTech' && (
                            <div
                              css={css`
                                a {
                                  margin-left: 20px;
                                  margin-top: 18px;
                                  display: inline-block;
                                  color: ${activePage
                                    ? mainColor
                                    : textColor} !important;
                                  font-weight: 400;
                                  font-size: 16px;
                                  line-height: 21px;
                                  ${Tablet} {
                                    margin-left: 24px;
                                  }
                                }
                              `}
                            >
                              <Link
                                to={`/${title}/${page}`}
                                onClick={() => {
                                  setOpen(false);
                                }}
                              >
                                {intl.formatMessage({ id: `menu.${page}` })}
                              </Link>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </Container>
            </div>
          );
        })}
        <div
          css={css`
            height: 2px;
            min-height: 2px;
            width: 100%;
            // background: #c9c9c9;
            margin-bottom: 40px;
          `}
        />
        <LanguageSwitcher />
        <div
          css={css`
            width: 100%;
            min-height: 110px;
            ${Tablet} {
              min-height: 140px;
            }
          `}
        />
      </ScrollContainer>
    </div>
  );
};

export default HamburgerMenu;
